<template>
	<div class="distribution_page">
		<el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
  			<el-breadcrumb-item >运营</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="distribution-head">
			<div class="top">
				<div class="left">
					<div>
						<img src="../../assets/img/ic_logo.png" alt="">
					</div>
					<div class="text">
						<div class="role">{{username}}</div>
						<div class="code">我的推荐码 {{info.passport}}</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" v-clipboard:copy="info.passport" v-clipboard:success="onCopy" v-clipboard:error="onError">复制推荐码</el-button>
				</div>
			</div>
			<div class="content">
				<div class="terms">
					<div class="item" style="background-color: #0486FE;">
						<router-link to="/distribution/distribution">
							<div class="text" style="color: #ffffff;">可提现余额<i class="el-icon-arrow-right"></i></div>
							<div class="number blue-number" style="color: #ffffff;height: 80px;">￥{{walletData.balance}}</div>
						</router-link>
					</div>
					<!-- <div class="item">
						<router-link to="/distribution/contribution">
							<div class="text" style="color: #333333;">贡献值<i class="el-icon-arrow-right"></i></div>
							<div class="number blue-number" style="height: 80px;">{{tableForm.score}}</div>
						</router-link>
					</div> -->
					<div class="item" @click="goTeam()">
						<div class="text">团队</div>
						<div class="number">{{info.team}}</div>
					</div>
					<div class="item" @click="goPPSteam()">
						<div class="text">直接销售</div>
						<div class="number">{{info.teamCount}}</div>
					</div>
					<div class="item" v-if="this.$store.state.base.userId == 33">
						<div class="text">团队PPS</div>
						<div class="number">{{info.teamPps}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="distribution-content">
			<el-tabs type="border-card">
  				<el-tab-pane label="奖励明细">
					  <div class="withdrawal"><el-button v-if="walletData.balance>0" type="primary" @click="centerDialogVisible = true">去提现</el-button></div>
					  <div class="transaction-list">
						<el-table :data="dealData" >
							<el-table-column prop="orderId" label="订单号" align="center"></el-table-column>
      						<el-table-column prop="reward" label="金额" align="center"></el-table-column>
							<el-table-column prop="createdAt" label="时间" align="center"></el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
								    <el-button type="primary" size="mini" @click="goDetail(scope.row)">查看详情</el-button>
								</template>
							</el-table-column>
    					</el-table>
						<div class="block">
						    <el-pagination  
							@current-change="handleCurrentChange1" 
							layout="prev, pager, next, jumper" 
							:page-size="page.pagesize"
							:current-page="page.currentPage"
							:total="page.total">
							</el-pagination>
						</div>
					  </div>
				</el-tab-pane>
  				<el-tab-pane label="提现明细">
					  <div class="withdrawal"><el-button v-if="walletData.balance>0" type="primary" @click="centerDialogVisible = true">去提现</el-button></div>
					  <div class="transaction-list">
						<el-table :data="recordData" style="width: 100%">
      						<!-- <el-table-column prop="date" label="序号" type="index" align="center"></el-table-column> -->
							<el-table-column prop="balance" label="提现金额" align="center"></el-table-column>
      						<el-table-column prop="createdAt" label="提现时间" align="center"></el-table-column>
							<el-table-column prop="checkedAt" label="审核时间" align="center"></el-table-column>
							<el-table-column label="状态" align="center">
								<template slot-scope="scope">
								    <span style="color: #1989FA;" v-if="scope.row.status===0">申请中</span>
									<span style="color: #84CE61;" v-if="scope.row.status===1">审核通过</span>
									<span style="color: #FF8C8D;" v-if="scope.row.status===-1">审核未通过</span>
								</template>
							</el-table-column>
							<el-table-column label="原因" align="center">
								<template slot-scope="scope">
								    <span style="color: #84CE61;" v-if="scope.row.reason != ''">{{scope.row.reason}}</span>
								</template>
							</el-table-column>
    					</el-table>
						<div class="block">
						    <el-pagination  
							@current-change="handleCurrentChange2" 
							layout="prev, pager, next, jumper" 
							:page-size="pages.pagesize"
							:current-page="pages.currentPage"
							:total="pages.total">
							</el-pagination>
						</div>
					  </div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-form ref="form" :model="form" label-width="120px">
			<el-dialog title="申请提现" :visible.sync="centerDialogVisible" width="30%" center>
  			<div class="popup">
				  <div class="top">
					  <div class="left">
						  <p class="text">可提现金额</p>
						  <p class="price">{{walletData.balance}}</p>
					  </div>
					  <div class="right">
						  <p class="text">输入提现金额</p>
						  <el-input v-model="form.nums" placeholder="请输入" class="popup-input"></el-input>
					  </div>
				  </div>
				  <div class="center">
					<div class="note">收款信息:</div>
					<el-select v-model="value" placeholder="请选择收款信息" @change="selectType">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.text"
					      :value="item.value">
					    </el-option>
					</el-select>
				  </div>
				  <div class="bottom">
				  </div>
			</div>
  			<span slot="footer" class="dialog-footer">
    			<el-button @click="centerDialogVisible = false">取 消</el-button>
    			<el-button type="primary" @click="centerDialogVisible = false,handleApply(form.nums)">申请提现</el-button>
  			</span>
		</el-dialog>
		</el-form>
	</div>	
</template>

<script>
import { Distribution,Personal } from "@/api/index";
export default {
    data() {
      return {
		value: 0,
		dealData:[],
		recordData:[],
		username:'',
		centerDialogVisible: false,
		form:{
			nums:'',
		},
		options: [{
				text: '银行汇款',
				value: 0
			},
			{
				text: '微信收款码',
				value: 1
			},
			{
				text: '支付宝收款码',
				value: 2
			},
		],
		walletData:{},
		recordData:[],
		page:{//交易
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		pages:{//提现
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		info:{},
      };
    },
	created() {
		this.Deallist();
		this.walletInfo();
		this.Recordlist()
		this.Info();
	},
	methods:{
		selectType(val) {
			this.value = val
		},
		goDetail(item) {
			this.$router.push({
				path: '/distribution/detail',
				query: {
					id: item.id,
					orderId: item.orderId
				}
			})
		},
		//个人信息
		async Info() {
			let { status, data } = await Personal.info();
			if (status) {
				if(data.code == 0) {
					this.info = data.data;
					if(this.info.truename==''|| typeof(this.info.truename) == 'undefined') {
						this.username = this.info.passport
					}else {
						this.username = this.info.truename
					}
				}
			}
		},
		//钱包信息
		async walletInfo() {
			let { status, data } = await Distribution.wallet();
			if (status) {
				if(data.code == 0) {
					this.walletData = data.data;
				}
			}
		},
		//收入
		async Deallist(page) {
				let { status, data } = await Distribution.incomelist({PageNum:page});
				if (status) {
					if(data.code == 0) {
						if (data.data.list === null) {
						} else {
							this.dealData = data.data.list;
							this.page.total = data.data.total;
							this.page.currentPage = data.data.currentPage;
						}
					}
				}
		},
		//交易分页
		async handleCurrentChange1(val) {
			this.page.currentPage = val;
			this.Deallist(this.page.currentPage)
		},
		//提现分页
		async handleCurrentChange2(val) {
			this.page.currentPage = val;
			this.Recordlist(this.page.currentPage)
		},
		async Recordlist(page) {
				let { status, data } = await Distribution.withdrawallist({PageNum:page});
				if (status) {
					if(data.code == 0) {
						if (data.data.list === null) {
						} else {
							this.recordData = data.data.list;
							this.pages.total = data.data.total;
							this.pages.currentPage = data.data.currentPage;
						}
					}
				}
		},
		async handleApply (nums) {
			this.form.nums = ''
			let { status, data} = await Distribution.withdrawal({balance: nums,payMode:this.value});
				if(status) {
					if(data.code == 0){
						this.$message.success("操作成功");
						this.Info();
						this.walletInfo();
						this.Recordlist();
					}else{
						this.$message.error(data.message);	
					}
				}
		},
		onCopy(e){ 　　 // 复制成功
			this.$message({
				message:'复制成功！',
				type:'success'
			})
		},
		onError(e){　　 // 复制失败
			this.$message({
				message:'复制失败！',
				type:'error'
			})
		},
		goTeam() {
			this.$router.push('/distribution/team')
		},
		goPPSteam() {
			this.$router.push('/distribution/ppsteam')
		}
	},
  };
</script>

<style lang="less">
	.distribution_page {
		.block {
			position: relative;
			.el-pagination {
				position: absolute;
				right: 20px;
				bottom: 0px;
				}
			}
	body {
			margin: 0;
			padding: 0;
			background-color: #F0F3FA;
		}
		.el-breadcrumb{
			padding: 30px;
			.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}
			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}
		.distribution-head {
			background-color: #fff;
			height: 336px;
			margin: 20px;
			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 125px;
				.left {
					display: flex;
					align-items: center;
					margin-left: 30px;
					.text {
						margin-left: 15px;
						.role {
							font-size: 22px;
							color: #333333;
							padding-bottom: 5px;
						}
						.code {
							font-size: 16px;
							color: #999999;
							padding-top: 5px;
						}
					}
				}
				.right {
					margin-right: 30px;
				}
			}
			.content {
				.terms {
					display: flex;
					margin: 0 80px;
					.item {
						width: calc(25% - 15px);
						height: 160px;
						margin-right: 20px;
						border-radius: 20px;
						background-color: #F9FAFC;
						cursor: pointer;
						.text {
							text-align: center;
							font-size: 20px;
							color: #333333;
							padding-top: 35px;
						}
						.number {
							text-align: center;
							font-size: 34px;
							color: #333333;
							padding-top: 15px;
						}
						.blue-number {
							color: #1493FA;
						}
					}
					.item:last-child {
						margin-right: 0;
					}
					.item:hover {
						background-color: #0486FE;
						.text {
							color: #ffffff!important;
						}
						.number {
							color: #ffffff;
						}
					}
				}
			}
		}
		.withdrawal {
			float: right;
	    	margin-right: 30px;
	    	margin-bottom: 20px;
		}
		.distribution-content {
			background-color: #fff;
			margin: 0 20px;
			.transaction-list{
				margin: 0 30px;
				.el-table {
					margin-bottom: 30px;
					padding-bottom: 20px;
				}
				.el-table::before {
					height: 0px;
					width: 0px;
				}
				.el-table th{
	    			background-color: #F5F7FA;
				}
			}
		}
		.popup {
			.center {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;
				.note {
					font-size: 16px;
					color: #333333;
					margin-right: 5px;
				}
			}
			.top {
				display: flex;
				height: 120px;
				border-bottom: 1px solid #E9E9E9;
				.left {
					width: 50%;
					text-align: center;
					border-right: 1px solid #E9E9E9;
					height: 86px;
					.text {
						font-size: 18px;
						color: #333333;
						padding-bottom: 20px;
					}
					.price {
						font-size: 30px;
						color: #0486FE;
					}
				}
				.right {
					width: 50%;
					text-align: center;
					height: 86px;
					.text {
						font-size: 18px;
						color: #333333;
						padding-bottom: 20px;
					}
					.popup-input {
						width: 200px;
					}
				}
			}
		}
	}

</style>